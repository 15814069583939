@font-face {
    font-family: 'FilsonProBold';
    src:url(../../fonts/FilsonProBold.woff2) format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'FilsonProHeavy';
    src:url(../../fonts/FilsonProHeavy.woff2) format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'FilsonProRegular';
    src:url(../../fonts/FilsonProRegular.woff2) format('woff2');
    font-weight:normal;
    font-style:normal;
}


@font-face {
    font-family: 'SofiaProRegular';
    src:url(../../fonts/SofiaProRegular.woff2) format('woff2');
    font-weight:normal;
    font-style:normal;
}

 @font-face {
    font-family: SofiaProRegularItalic;
    src: local("SofiaProRegularItalic"),
      url("../../fonts/SofiaProRegularItalic.otf")
        format("opentype");
  }

@font-face {
    font-family: 'SofiaPro-Bold';
    src:url(../../fonts/SofiaPro-Bold.woff2) format('woff2');
    font-weight:bold;
    font-style:normal;
}


.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.form-control-dark {
  color: #fff;
  background-color: var(--bs-dark);
  border-color: var(--bs-gray);
}
.form-control-dark:focus {
  color: #fff;
  background-color: var(--bs-dark);
  border-color: #fff;
  box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .25);
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.text-small {
  font-size: 85%;
}

.dropdown-toggle {
  outline: 0;
}

.fm-header{
	height:173px;
	background-color:#002B1D;
}

.fm-header-mob{
	height:126px;
	background-color:#002B1D;
}

.fm-padtop{
	padding-top:45px;
	padding-bottom:10px;
	border-bottom:1px solid #E7D0FF;
}

.fm-padtop-mob{
	padding-top:45px;
	padding-bottom:10px;
}


.fm-hambuuger{
	padding-left:50px;	
}

.fm-logout-btn{
		width:232px;
		height:60px;
		background: #CBE1A2;
		box-shadow: 0px 3px 6px #00000029;
		border-radius: 30px;
		opacity: 1;
		text-align:center;
		vertical-align:middle;
		 font-family: 'SofiaPro-Bold';
	font-size:20px;
	line-height:60px;
	color:#6C4BA9 !important;
	text-decoration:none;
	display:block;

}

.fm-kundeservice-btn,  .fm-kundeservice-btn:hover{
    font-family: 'FilsonProBold';
	font-size:16px;
	line-height:60px;
	color:#E7D0FF;
	text-decoration:none;
	display:block;
}

/*.fm-logout-btn a{
    font-family: 'SofiaPro-Bold';
	font-size:20px;
	line-height:60px;
	color:#6C4BA9 !important;
	text-decoration:none;
	display:block;
}*/

.fm-content-pink{
	background-color:#E7D0FF;	
}


.fm-content-green{
	background-color:#002B1D;	
}

.fm-h1{
    font-family: 'FilsonProHeavy';
	font-size:36px;
	line-height:60px;
	color:#002B1D;
	text-align:center;
}


.fm-green-box{
	background-color:#002B1D;
	width:314px;
	height:544px;
	border-radius:28px;
}


.fm-green-box h1{
    font-family: 'FilsonProHeavy';
	font-size:26px;
	line-height:30px;
	color:#E7D0FF;
	padding-top:23px;
	text-align:center;
}

.fm-green-box .tag-line{
    font-family: 'SofiaProRegularItalic';
	color: #E7D0FF;
	margin-top: -12px;
}


.fm-green-box h3{
    font-family: 'FilsonProRegular';
	font-size:16px;
	line-height:25px;
	color:#CBE1A2;
	text-align:center;
}


.fm-green-box h2{
    font-family: 'FilsonProHeavy';
	font-size:45px;
	line-height:25px;
	color:#CBE1A2;
	text-align:center;
	padding-top:26px;
}


.fm-green-box h2 span{
    font-family: 'FilsonProHeavy';
	font-size:25px;
	text-align:center;
}

.fm-green-box ul li{
	font-family: 'SofiaProRegular';
	font-size:14px;	
	line-height:20px;
	margin-bottom: 25px;
	color:#E7D0FF;
	list-style-image:url(../../images/bullet.png);
}

.fm-box-list{
	padding-top:20px;
	padding-left:10px;
	padding-right:10px;
}

.fm-boxTop{
	font-family: 'SofiaPro-Bold';
	font-size:18px;
	line-height:34px;
	font-weight:bold;
	color:#002B1D;
	width:314px;
	height:100px;
	border-radius:28px;
	background-color:#CBE1A2;
	padding-top:5px;
}

.withtopBar{
	margin-top:-50px;
	z-index:1000;	
}

.withouttopBar{
	margin-top:55px;	
}


.fm-box-btn{
	width:275px;
	height:50px;
	background: #CBE1A2;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 30px;
	opacity: 1;
	text-align:center;
	vertical-align:middle;
	margin-top:70px;
}

.fm-box-btn a{
    font-family: 'SofiaPro-Bold';
	font-size:20px;
	line-height:50px;
	color:#002B1D;
	text-decoration:none;
	display:block;
}

.fm-bott-button{
	width:275px;
	height:50px;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 25px;
	opacity: 1;
	margin-top:28px;
	text-align:center;
	font-family: 'SofiaPro-Bold';
	font-size:20px;
	line-height:50px;
	color:#002B1D;
	text-decoration:none;
	display:block;
}
.fm-bott-button:hover{
	color:#002B1D;
}
.fm-bott-button a{
    font-family: 'SofiaPro-Bold';
	font-size:20px;
	line-height:50px;
	color:#002B1D;
	text-decoration:none;
	display:block;
}

.fm-bott-link{
	height:50px;
	margin-top:28px;
	font-family: 'SofiaPro-Bold';
	font-size:20px;
	line-height:50px;
	color:#002B1D;
	display:block;
}
.fm-bott-link:hover{
	color:#002B1D;
}

.fm-bott-link a{
    font-family: 'SofiaPro-Bold';
	font-size:20px;
	line-height:50px;
	color:#002B1D;
	display:block;
}


.fm-green-box-big{
	background-color:#002B1D;
	padding:20px 0px;
	border-radius:28px;
}


.fm-green-box-big h1{
    font-family: 'FilsonProHeavy';
	font-size:32px;
	line-height:45px;
	color:#CBE1A2;
	padding-top:23px;
	text-align:center;
}


.fm-green-box-big h3{
    font-family: 'SofiaProRegular';
	font-size:18px;
	line-height:25px;
	color:#CBE1A2;
	text-align:center;
	padding-top:17px;
}


.fm-green-box-big h2{
    font-family: 'FilsonProHeavy';
	font-size:28px;
	line-height:40px;
	color:#E7D0FF;
	text-align:center;
	padding-top:26px;
}


.fm-green-box-big h2 span{
    font-family: 'FilsonProHeavy';
	font-size:25px;
	text-align:center;
}


.fm-bigbox-btn{
	width:275px;
	height:50px;
	background: #CBE1A2;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 30px;
	opacity: 1;
	text-align:center;
	vertical-align:middle;
	margin-top:50px;
}

.fm-bigbox-btn a{
    font-family: 'SofiaPro-Bold';
	font-size:20px;
	line-height:50px;
	color:#002B1D;
	text-decoration:none;
	display:block;
}

.fm-bigBox-link{
	height:50px;
	margin-top:28px;
}


.fm-bigBox-link a{
    font-family: 'SofiaPro-Bold';
	font-size:20px;
	line-height:50px;
	color:#FFFFFF;
	display:block;
}




.content_green{
	/*padding-bottom:80px;*/
}


.content_green h1{ 
	font-family: 'FilsonProHeavy';
	font-size:36px;
	color:#FFF;
}


.content_green p{ 
	font-family: 'SofiaPro-Bold';
	font-size:18px;
	line-height:20px;
	color:#FFF;
	padding-top:5px;
}

.content_green p span{
	color:#CBE1A2;
}

.fm-accordion-area{
	margin-top:55px;	
}

.fm-accordion-textarea{
	padding-left:20px;
}

.fm-accordion-btn{
	width:100%;
	height:78px;
	background-color:#FFF;
	margin-top:10px;
	margin:10px auto;
}

.fm-accordion-btn:hover{
	/*width:546px;*/
	height:78px;
	line-height:73px;
	background-color:#FFF;
	/*border:5px solid #A5D280;*/
}

.fm-accordion-btn-active{
	border:5px solid #A5D280;
}

.fm-accordion-area a{
	display:block;
	text-decoration:none;
	padding:0px;
	margin:0px;
}

.fm-accordion-btn h1{
	font-family: 'FilsonProHeavy';
	font-size:20px;
	line-height:78px;
	color:#012B1D;
	padding-left:10px;
}

.fm-accordion-btn h2{
	font-family: 'FilsonProHeavy';
	font-size:38px;
	line-height:78px;
	color:#012B1D;
	padding-left:20px;
}
.fm-accordion-content-mobile{
	background-color: #FFF;
	padding: 0px 20px 20px 20px;
	margin-top: -10px;
	
}
.fm-accordion-content-mobile .fm-bott-button{
	width:275px;
	height:50px;
	background: #002B1D 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 25px;
	opacity: 1;
	margin-top:28px;
	text-align:center;
	font-family: 'SofiaPro-Bold';
	font-size:20px;
	line-height:50px;
	color:#FFF;
	text-decoration:none;
	display:block;
}
 .fm-accordion-content-mobile .p3, .fm-accordion-content-mobile .p1{
 	font-size: 16px;
 }
.fm-accordion-content-mobile .p1, .fm-accordion-content-mobile .p3, .fm-accordion-content-mobile .p4{
	color:#012B1D; 
	font-family: 'SofiaProRegular';
}
.fm-accordion-content-mobile .p2, .fm-accordion-content-mobile strong{
	color:#012B1D; 
	font-family: 'SofiaPro-Bold';
}
.fm-accordion-content{
	
}

.fm-accordion-content h1{ 
	font-family: 'FilsonProHeavy';
	font-size:36px;
	color:#FFF;
	padding-bottom:10px;
}

.fm-accordion-content h3{ 
	font-family: 'FilsonProHeavy';
	font-size:24px;
	color:#FFF;
	padding-bottom:10px;
}


.fm-accordion-content p{ 
	font-family: 'SofiaProRegular';
	font-size:18px;
	line-height:20px;
	color:#FFF;
}


.fm-accordion-content p.smaller{ 
	font-size:14px;
}

.fm-accordion-content p span{
	font-size:24px;
}

.accordion-button{
	margin-top:16px;
	font-size:16pt;
	font-family:'FilsonProHeavy';
	
}

.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image:url(../../images/plus-symbol-button.svg);
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
}

.accordion-button:not(.collapsed)::after {
    background-image:url(../../images/minus-sign-of-a-line-in-horizontal-position.svg);
    transform: rotate(-180deg);
}
.fm-login-form-label{
	font-family: 'SofiaProRegular';
	font-size:16px;
	line-height:20px;
	color:#FFF;
	padding-top:5px;
	margin-left: 15px;
}
.InputContainer input {
	font-size: 222px !important;
  padding: 15px;
  width: 100%;
  margin-top: 11px;
  color: #002B1D;
  font-family: 'FilsonProHeavy';
}
.fm-login-form-input {
	font-size: 16px;
  padding: 15px;
  width: 100%;
  margin-top: 11px;
  color: #002B1D;
  font-family: 'FilsonProHeavy';
  background-color: #FFFFFF;
  padding: 18px;
}
.fm-login-form-input-small{
	font-size: 16px;
  padding: 15px;
  width: 50%;
  margin-top: 11px;
  color: #002B1D;
  font-family: 'FilsonProHeavy';
}

.fm-login-btn, .fm-login-btn:hover{
	border:none;
	width:275px;
	height:50px;
	background: #CBE1A2;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 30px;
	opacity: 1;
	text-align:center;
	vertical-align:middle;
	margin-top:70px;
	 font-family: 'SofiaPro-Bold';
	font-size:20px;
	line-height:50px;
	color:#002B1D;
	text-decoration:none;
	display:block;
}
.fm-forgot-pass-link, .fm-forgot-pass-link:hover{
	 font-family: 'FilsonProRegular';
	font-size:16px;
	line-height:60px;
	color:#E7D0FF;
	text-decoration:none;
	display:block;
	text-decoration: underline;
}
.fm-login-heading{
	font-family: 'FilsonProHeavy';
	font-size:36px;
	line-height:60px;
	color:#FFF;
	text-align:center;
}

input.form-check-input-comp[type="radio"]{
	border:0px;
	height:22px;
	width:22px;
}

input.form-check-input-comp[type="radio"]:checked, input.form-check-input-comp[type="checkbox"]:checked{
	background-color:#002B1D;
	border:0px;
	height:22px;
	width:22px;
	color:#002B1D;
}

input[type="radio"]{
	border:0px;
	height:31px;
	width:31px;
}

input[type="radio"]:checked, input[type="checkbox"]:checked{
	background-color:#A5D280;
	border:0px;
	height:31px;
	width:31px;
	color:#002B1D;
}

input[type="checkbox"]{
	width:21px;
	height:21px;	
	cursor: pointer;
}


input[type="checkbox"]:checked{
	background-color:#A5D280;
	border:0px;
	height:21px;
	width:21px;
	color:#002B1D;
}

.form-check-input:checked[type=checkbox] {
    /*background-image: url("data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="512" height="512" x="0" y="0" viewBox="0 0 417.813 417" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path d="M159.988 318.582c-3.988 4.012-9.43 6.25-15.082 6.25s-11.094-2.238-15.082-6.25L9.375 198.113c-12.5-12.5-12.5-32.77 0-45.246l15.082-15.086c12.504-12.5 32.75-12.5 45.25 0l75.2 75.203L348.104 9.781c12.504-12.5 32.77-12.5 45.25 0l15.082 15.086c12.5 12.5 12.5 32.766 0 45.246zm0 0" fill="#002b1d" opacity="1" data-original="#000000" class=""></path></g></svg>"); */
    background-image: url("../../images/check.png");
    background-size: 16px;
  background-position-y: 5px;

}
.myaccount-checkbox-label{
	font-size: 16px;
  color: #FFF;
  font-family: SofiaProRegular;
  padding-left: 10px;
}


::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #185b46;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.my-account-fields {
  scrollbar-width: thin;
  scrollbar-color:#185b46 #01281be0;
}

/* Firefox scrollbar thumb */
.my-account-fields::-moz-scrollbar-thumb {
  background-color:#185b46;
  border-radius: 20px;

}

/* Firefox scrollbar thumb on hover */
.my-account-fields::-moz-scrollbar-thumb:hover {
  background-color: #555;
  border-radius: 10px;
}

/* Example content with long height */
.my-account-fields {
  max-height: 700px;
  padding-top: 80px;
  overflow: auto;
}


.payment-bold{
    font-family: 'SofiaPro-Bold';
	font-size:22px;
	color:#FFF;
	display:block;
}

.payment-reg{
    font-family: 'SofiaProRegular';
	font-size:18px;
	color:#FFF;
	display:block;
}
.payment-reg-small{
    font-family: 'SofiaProRegular';
	font-size:15px;
	
	color:#FFF;
	display:block;
}
.boldundlinks, .boldundlinks:hover{
	color: #FFF;
	text-decoration: underline;
	font-weight: bold;
}
.vipps-button{
	background-image: url("../../images/vipps-logo.png");
	width:170px;
	height:50px;
	background-color: #F63;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 25px;
	opacity: 1;
	margin-top:28px;
	
	display: block;
	background-size: 90px;
	background-repeat: no-repeat;
	background-position: center;
}
.strex-button{
	background-image: url("../../images/strex-logo.png");
	width:170px;
	height:50px;
	background-color: #FFF;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 25px;
	opacity: 1;
	margin-top:8px;
	
	display: block;
	background-size: 90px;
	background-repeat: no-repeat;
	background-position: center;
}
.input-round-corner{
	border-radius: 35px;
  outline: none;
  background: white;
  box-shadow: none;
  border: 1px solid #FFF;
}


.history-content {
  background-color: #FFF;
  padding:15px;
  font-family: SofiaPro-Bold;
  color: #000;
}
.history-content .item{
	padding: 15px;
  border-bottom: 1px solid #002B1D;
  padding-bottom: 40px;
}
.history-content  .item-separator{
border-bottom: 1px solid #012B1D;
}
.history-content .item a{
	font-family: 'SofiaPro-Bold';
	color:#000000;
	display:block;
	text-decoration: underline;
}
.cb{
	clear: both;
}
.fr{
	float: right;
}
.fl{
	float: left;
}
.sofiaprobold{
 font-family: 'SofiaPro-Bold';
}
.sofiaproreg{
 font-family: 'SofiaProRegular';
}
.fontsize16{
	font-size: 16px;
}
.fontsize18{
	font-size: 18px;
}
.fontsize20{
	font-size: 20px;
}
.fm-load-more, .fm-load-more:hover{
	width:275px;
	height:50px;
	background: #012B1D;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 30px;
	opacity: 1;
	text-align:center;
	vertical-align:middle;
	margin-top:70px;
	 font-family: 'SofiaPro-Bold';
	font-size:20px;
	line-height:50px;
	color:#FFF;
	text-decoration:none;
	display:block;
}
.no-padding > * {
  padding-right: 0;
  padding-left: 0;
}

.minkonto-tab-mobile div{
	font-size: 20px;
	font-family: 'SofiaPro-Bold';
	color: #012B1D;
	text-align: left;
}
.minkonto-tab-mobile  .edit-link {
	text-align: right;
}
.edit-link a {
	font-family: 'SofiaPro-Bold';
	font-size:20px;
	color:#012B1D;
	display:block;
	text-decoration: underline;
}

.minkonto-tab-mobile .item{
	border-bottom: 1px solid #012B1D;
	margin: 0px auto;
	height: 70px;
  padding-top: 20px;
}
.exit-text{
	font-size: 16px !important;
	font-family: 'SofiaProRegular' !important;
}

.form-check-label{
	font-size:18px;
	color:#FFF;
	font-family: 'SofiaPro-Bold';
	font-weight:700;
	padding-left:10px;
	line-height:36px;
}

.fm-form-padding{
	margin:20px 0px 20px 0px;
}

.fm-form-padding{
	margin:20px 0px 20px 0px;
}

.fm-form-btn-white{
	width:275px;
	height:50px;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 25px;
	opacity: 1;
	margin:28px auto;
	text-align:center;
}

.fm-form-btn-white a{
    font-family: 'SofiaPro-Bold';
	font-size:20px;
	line-height:50px;
	color:#002B1D;
	text-decoration:none;
	display:block;
}

.fm-form-btn-green{
	width:275px;
	height:50px;
	background: #CBE1A2;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 30px;
	opacity: 1;
	text-align:center;
	vertical-align:middle;
	margin:28px auto;
}

.fm-form-btn-green a{
    font-family: 'SofiaPro-Bold';
	font-size:20px;
	line-height:50px;
	color:#002B1D;
	text-decoration:none;
	display:block;
}


.green-msg-box{
	width:100%;
	padding:20px;
	background-color:#CBE1A2;	
}

.green-msg-box p{
	font-size:14px;
	color:#002B1D;
	text-align:left;
}


.green-msg-box a{
	font-size:14px;
	color:#002B1D;
	text-align:left;
}

.fm-form-text input{
	color:#ADADAD;
	font-family: 'FilsonProHeavy';
	font-size:20px;
}

.form-check-label-comp{
	font-size:18px;
	color:#002B1D;
	font-family: 'SofiaPro-Bold';
	font-weight:700;
	padding-left:10px;
	line-height:36px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: #CBE1A2;
  padding: 20px;
  border-radius: 0px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 700px;


}
.popup h1 {
	font-size: 22px;
	font-family: 'FilsonProBold';
}
.popup p {
	font-size: 16px;
	font-family: 'SofiaProRegular';

}
.popup h1, .popup p  {
	color: #002B1D;

}
.popup .popup_content{
	padding: 30px 50px;

}
.popup .popup_close{
	float: right;

}
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.strike{
	text-decoration:line-through
}
.greyout{
	color: #BBBBBB !important;
}
.fabel-alert{
	font-family: 'SofiaProRegular'
}
.handlesubmitpasswordmsg{
	font-family: 'FilsonProBold';
	font-size: 16px;
	color: #CBE1A2;
}
.exit-input{
	font-family: 'FilsonProRegular';
	color: #212529;
	font-weight: 400;
	border-radius: 0px;
  	resize: none;
  	font-size: 16px;
}

